import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading3, CaptionSmall } from '@components/TextStyles'
import { ImageTextStyle, ImageWrapper } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { colors } from '@styles/vars/colors.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from '@components/Button'
import RichText from '@components/RichText'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateImage from '@components/animation/AnimateImage'

const ImageText = ({
  id,
  title,
  description,
  byline,
  image,
  podcastLink,
  // podcastButtons,
}) => {
  return (
    <ImageTextStyle id={id}>
      <Container>
        <Spacer size={[80, 190]} />
        <Grid>
          <GridItem
            tabletP={10}
            tabletPStart={2}
            tabletL={8}
            tabletLStart={3}
            desk={3}
            deskStart={2}
          >
            <ImageWrapper>
              <AnimateImage>
                <GatsbyImage image={image.gatsbyImageData} alt="" />
              </AnimateImage>
            </ImageWrapper>
          </GridItem>

          <GridItem
            tabletP={10}
            tabletPStart={2}
            tabletL={8}
            tabletLStart={3}
            desk={5}
            deskStart={6}
          >
            <Spacer size={[21, 24]} />
            <Heading3 color={colors.pink2}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading3>
            <Spacer size={10} />
            <CaptionSmall>
              <AnimateSplitText>{byline}</AnimateSplitText>
            </CaptionSmall>
            <Spacer size={[38, 40]} />
            <RichText content={description} />
            <Spacer size={[15, 19]} />
            <AnimateSlideIn>
              <Button
                variant="secondary"
                type="externalLink"
                href={podcastLink.url}
                target="_blank"
              >
                {podcastLink.text}
              </Button>
            </AnimateSlideIn>

            {/* {podcastButtons.length ? (
              <IconWrapper>
                {React.Children.toArray(
                  podcastButtons.map(podcast => {
                    return (
                      <a href={podcast.url} target="_blank">
                        <img
                          src={podcast.podcastImage.file.url}
                          alt={podcast.title}
                        />
                      </a>
                    )
                  })
                )}
              </IconWrapper>
            ) : null} */}
          </GridItem>
        </Grid>
        <Spacer size={[106, 211]} />
      </Container>
    </ImageTextStyle>
  )
}

ImageText.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.object,
  byline: PropTypes.string,
  image: PropTypes.object,
  podcastButtons: PropTypes.array,
}

export default ImageText
