import React, { useEffect } from 'react'
import { FullImageStyle, FullImageInner } from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import { useStore } from '@Store/'
import { setHeaderLight } from '@Store/'
import { setHeaderDark } from '@Store/'

const FullImage = ({ image }) => {
  const [headerCheckRef, headerCheck] = useInView({
    triggerOnce: false,
    rootMargin: '-15% 0px -85% 0px',
  })
  const [inViewRef, inView] = useInView({
    triggerOnce: false,
    rootMargin: '-30% 0px -30% 0px',
  })
  const [, dispatch] = useStore()

  useEffect(() => {
    if (headerCheck) {
      setHeaderLight(dispatch)
    } else {
      setHeaderDark(dispatch)
    }
  }, [headerCheck, dispatch])

  return (
    <FullImageStyle ref={headerCheckRef}>
      <FullImageInner ref={inViewRef} inView={inView}>
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </FullImageInner>
    </FullImageStyle>
  )
}

FullImage.propTypes = {}

export default FullImage
