import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { Heading1 } from '@styles/vars/textStyles.style'
import { grid } from '@styles/vars/grid.style'
import { ContainerMain } from '@components/Container/index.style'

export const HeroMain = styled.div`
  color: ${colors.dark};
  overflow: hidden;
  position: relative;

  ${ContainerMain} {
    padding-bottom: 23.1rem;
    position: relative;

    ${mq.desk} {
      padding-bottom: 12.3rem;
    }
  }
`

export const HeroText = styled.div`
  ${Heading1} {
    ${mq.desk} {
      /* ${clamp(
        'max-width',
        350,
        450,
        breakpoints.desk,
        breakpoints.container
      )}; */
    }
  }
`

export const HeroCards = styled.div`
  ${clamp('bottom', -100, -277)};
  display: flex;
  position: absolute;
  ${clamp('right', -30, -106)};
`

export const HeroCardWrap = styled.div`
  align-self: center;

  &:first-child {
    ${clamp('margin-right', grid.gap.min, grid.gap.max)};
  }
`

export const HeroCard = styled.div`
  ${clamp('height', 150, 406)};
  ${clamp('width', 105, 284)};
`
