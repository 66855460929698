import styled from 'styled-components'

import { mq } from '../../../styles/vars/media-queries.style'

export const ImageTextStyle = styled.div``

export const ImageWrapper = styled.div`
  img {
    width: 100%;
  }
  ${mq.desk} {
    padding-left: 6.3rem;
  }
`
export const IconWrapper = styled.div`
  display: flex;

  img {
    max-width: 6.5rem;
    margin-right: 2.2rem;
  }
`
