import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import Hero from '@components/About/Hero'
import ImageText from '@components/About/ImageText'
// import Credit from '@components/About/Credits'
import FullImage from '@components/About/FullImage'
import { useStore, setHeaderDark } from '@Store/'
import Footer from '@components/Footer'
import gsap from 'gsap'
import { animation } from '@styles/vars/animation.style'
import queryString from 'query-string'

const AboutPage = ({ data, location }) => {
  const [store, dispatch] = useStore()
  const scrollToPodcast = Object.keys(
    queryString.parse(location.search)
  ).includes('podcast')

  useEffect(() => {
    gsap.delayedCall(animation.maskShowDuration, () => setHeaderDark(dispatch))
  }, [dispatch])

  useEffect(() => {
    if (
      scrollToPodcast &&
      store.smoothScroll &&
      !store.showPageMask &&
      !store.showIntroMask
    ) {
      gsap.to(store.smoothScroll, {
        scrollTop: store.smoothScroll.offset('#podcast'),
        duration: 0.5,
        ease: 'power2.inOut',
      })
    }
  }, [
    scrollToPodcast,
    store.smoothScroll,
    store.showPageMask,
    store.showIntroMask,
  ])

  const {
    seo,
    slug,
    aboutFullWidthImage,
    aboutTitle,
    aboutCards,
    introText,
    // creditTitle,
    // creditDescription,
    podcastLink,
    podcastByline,
    podcastTitle,
    podcastImage,
    podcastText,
    // podcastButton,
    // sponsorLogo,
  } = data.contentfulAbout

  return (
    <>
      <Seo data={seo} slug={slug} />
      <Hero title={aboutTitle} description={introText} cards={aboutCards} />
      <FullImage image={aboutFullWidthImage} />
      <ImageText
        id="podcast"
        title={podcastTitle}
        image={podcastImage}
        description={podcastText}
        byline={podcastByline}
        // podcastButtons={podcastButton}
        podcastLink={podcastLink}
      />
      {/* <Credit
        title={creditTitle}
        text={creditDescription}
        logos={sponsorLogo}
      /> */}
      <Footer />
    </>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query About {
    contentfulAbout {
      seo {
        ...SEO
      }
      slug
      aboutTitle
      introText {
        raw
      }
      aboutFullWidthImage {
        gatsbyImageData
      }
      aboutCards {
        gatsbyImageData
        description
      }
      podcastTitle
      podcastText {
        raw
      }
      podcastImage {
        gatsbyImageData
      }
      podcastLink {
        text
        url
      }
      thankYouTitle
      podcastByline
      creditTitle

      creditDescription {
        raw
      }
      sponsorLogo {
        file {
          url
        }
        description
      }
    }
  }
`
