import React from 'react'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading1 } from '@components/TextStyles'
import {
  HeroMain,
  HeroText,
  HeroCards,
  HeroCardWrap,
  HeroCard,
} from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { colors } from '@styles/vars/colors.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import RichText from '@components/RichText'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'

const Hero = ({ title, description, cards }) => {
  return (
    <HeroMain>
      <Container>
        <Spacer size={[126, 190]} />
        <Grid>
          <GridItem tabletP={8} desk={6} deskStart={2}>
            <HeroText>
              <Heading1 color={colors.pink2} maxWidth={4.7}>
                <AnimateSplitText type={`lines,chars`}>
                  {title}
                </AnimateSplitText>
              </Heading1>
              <Spacer size={[31, 34]} />
              <RichText content={description} />
            </HeroText>
          </GridItem>
        </Grid>
        <HeroCards>
          <HeroCardWrap>
            <HeroCard>
              <AnimateImage rootMargin="0px" delay={animation.imageStagger}>
                <GatsbyImage
                  image={cards[0].gatsbyImageData}
                  alt={cards[0].description}
                />
              </AnimateImage>
            </HeroCard>
          </HeroCardWrap>
          <HeroCardWrap>
            <HeroCard>
              <AnimateImage rootMargin="0px" delay={animation.imageStagger * 2}>
                <GatsbyImage
                  image={cards[1].gatsbyImageData}
                  alt={cards[1].description}
                />
              </AnimateImage>
            </HeroCard>
            <Spacer size={[10, 24]} />
            <HeroCard>
              <AnimateImage rootMargin="0px" delay={animation.imageStagger * 3}>
                <GatsbyImage
                  image={cards[2].gatsbyImageData}
                  alt={cards[2].description}
                />
              </AnimateImage>
            </HeroCard>
          </HeroCardWrap>
        </HeroCards>
      </Container>
    </HeroMain>
  )
}

Hero.propTypes = {}

export default Hero
