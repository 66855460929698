import { colors } from '@styles/vars/colors.style'
import { easings } from '@styles/vars/easings.style'
import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const FullImageStyle = styled.div`
  position: relative;
  width: 100%;
  object-fit: cover;

  .gatsby-image-wrapper {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  ${mq.desk} {
    .gatsby-image-wrapper {
      aspect-ratio: auto;
    }
  }
`

export const FullImageInner = styled.div`
  position: relative;

  &:after {
    background-color: ${colors.dark};
    bottom: 0;
    content: '';
    left: 0;
    opacity: ${props => (props.inView ? 0 : 1)};
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 1s ${easings.out.cubic};
    z-index: 2;
  }
`
